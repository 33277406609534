import React, { FC } from 'react';
import { withPrefix } from 'gatsby';
import { Router } from '@reach/router';
import { Route } from 'components';

import BaseResults from './index';
import OldPayments from '../oldPayments';

const Results: FC = () => (
  <Router basepath={withPrefix('/start/results-popup')}>
    {/* {location.search.includes('tst3')
      ? undefined
      : Route('/payments', OldPayments)} */}
    {Route('/:code', BaseResults)}
  </Router>
);

export default Results;
